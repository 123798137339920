import Vue from 'vue'
import Router from 'vue-router'
import VueResource from 'vue-resource'
Vue.use(VueResource)
Vue.use(Router)
Vue.use(require('vue-cookies'))

export default new Router({
  mode: 'history',
  hash: false,
  routes: [
    {
      path: '*',
      redirect: { name: 'dashboard' },
    }, {
      name: 'branding',
      path: '/branding',
      component: () => import('../components/branding/Branding.vue'),
      children: [],
    }, {
      name: 'dashboard',
      path: '/customize',
      component: () => import('../components/dashboard/Dashboard.vue'),
      children: [],
    }, {
      name: 'food',
      path: '/food',
      component: () => import('../components/food/Food.vue'),
      children: [],
    }, {
      name: 'location',
      path: '/places',
      component: () => import('../components/location/Location.vue'),
      children: [],
    }, {
      name: 'service',
      path: '/services',
      component: () => import('../components/service/Service.vue'),
      children: [],
    }, {
      name: 'integration',
      path: '/integration',
      component: () => import('../components/integration/Integration.vue'),
      children: [],
    }, {
      name: 'shipping',
      path: '/shipping',
      component: () => import('../components/shipping_details/ShippingDetails.vue'),
      children: []
    }, {
      name: 'notification',
      path: '/shipping',
      component: () => import('../components/notification/Notification.vue'),
      children: []
    }, {
      name: 'customization',
      path: '/complete',
      component: () => import('../components/customization/Customization.vue'),
      children: []
    }
  ],
})
