var routes_data =
[
  {
    name: 'dashboard',
    displayName: 'menu.dashboard',
    meta: {
      iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
      authorize: ['SYS_ADMIN', 'MSO_ADMIN', 'CMS_MANAGER', 'SUPER_ADMIN'],
    },
  },
  // {
  //     name: 'token',
  //     displayName: 'Token',
  //     meta: {
  //         iconClass: 'fa fa-key',
  //         authorize: ['SYS_ADMIN'],
  //     }
  // },
  {
    name: 'user',
    displayName: 'User',
    meta: {
      iconClass: 'fa fa-user',
      authorize: ['SUPER_ADMIN'],
    },
    disabled: true,
  },
  {
    name: 'interface',
    displayName: 'Interface',
    meta: {
      iconClass: 'fa fa-list',
      authorize: ['SYS_ADMIN', 'MSO_ADMIN', 'CMS_MANAGER', 'SUPER_ADMIN'],
    },
    disabled: true,
  },
  {
    name: 'config',
    displayName: 'Domain',
    meta: {
      iconClass: 'fa fa-certificate',
      authorize: ['SUPER_ADMIN'],
    },
    disabled: true,
  },
  {
    name: 'acl',
    displayName: 'ACL',
    meta: {
      iconClass: 'fa fa-lock',
      authorize: ['SUPER_ADMIN'],
    },
    disabled: true,
  },
  {
    name: 'upgrade',
    displayName: 'Upgrade',
    meta: {
      iconClass: 'fa fa-upload',
      authorize: ['MSO_ADMIN', 'SUPER_ADMIN'],
    },
    disabled: true,
  },
  {
    name: 'content',
    displayName: 'Content',
    meta: {
      iconClass: 'fa fa-plus',
      authorize: ['SYS_ADMIN', 'MSO_ADMIN', 'CMS_MANAGER', 'SUPER_ADMIN'],
    },
    disabled: true,
  },
  {
    name: 'troubleshoot',
    displayName: 'Troubleshoot',
    meta: {
      iconClass: 'vuestic-iconset vuestic-iconset-components',
      authorize: ['SYS_ADMIN', 'MSO_ADMIN', 'SUPER_ADMIN'],
    },
    disabled: true,
    children: [
      {
        name: 'ping',
        displayName: 'Ping/Traceroute',
        meta: {
          iconClass: 'fa fa-lock',
          authorize: ['SYS_ADMIN', 'CMS_MANAGER', 'SUPER_ADMIN'],
        },
        disabled: true,
      },
      {
        name: 'reboot',
        displayName: 'Reboot',
        meta: {
          authorize: ['SYS_ADMIN', 'MSO_ADMIN', 'SUPER_ADMIN'],
        },
      },
    ],
  },
  {
    name: 'multicast',
    displayName: 'Multicast',
    meta: {
      iconClass: 'entypo entypo-database',
      authorize: ['SUPER_ADMIN'],
    },
    disabled: true,
  },
  {
    name: 'settings',
    displayName: 'Settings',
    meta: {
      iconClass: 'vuestic-iconset vuestic-iconset-ui-elements',
      authorize: ['SYS_ADMIN', 'MSO_ADMIN', 'CMS_MANAGER', 'SUPER_ADMIN'],
      flag: [],
    },
    disabled: true,
    children: [
      {
        name: 'profile',
        displayName: 'Profile',
        meta: {
          flag: [],
          authorize: ['CMS_MANAGER', 'SUPER_ADMIN', 'SYS_ADMIN', 'MSO_ADMIN'],
        },
        disabled: true,
      },
      {
        name: 'backup',
        displayName: 'Backup',
        meta: {
          iconClass: 'fa fa-database',
          authorize: ['CMS_MANAGER', 'SUPER_ADMIN'],
        },
        disabled: true,
      },
      {
        name: 'about',
        displayName: 'About',
        meta: {
          flag: [],
          authorize: ['CMS_MANAGER', 'SUPER_ADMIN', 'SYS_ADMIN', 'MSO_ADMIN'],
        },
        disabled: true,
      },
      {
        name: 'login',
        displayName: 'Logout',
        meta: {
          authorize: ['SYS_ADMIN', 'MSO_ADMIN', 'CMS_MANAGER', 'SUPER_ADMIN'],
        },
        disabled: true,
      },
    ],
  },
]

export const getNavigationRoutes = () => {
  const role = localStorage.getItem('userRole')
  return routes_data
}

var navigationRoutes = {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: getNavigationRoutes(),
  routes_copy: routes_data,
}

export { navigationRoutes }
